.imageAndDescription {
  margin: 0;
  & + div {
    margin: var(--spacing-8) 0 var(--spacing-14);
  }
}

.description {
  color: var(--color-tarmac-grey-500);
  margin: var(--spacing-3) 0;
}

.image {
  & img {
    width: calc(100% + var(--spacing-5) * 2);
    margin: 0 calc(var(--spacing-5) * -1);
    max-width: none;
    object-fit: cover;
  }
}

@media (--tablet) {
  .image {
    & img {
      width: calc(100% + var(--spacing-6) * 2);
      margin: 0 calc(var(--spacing-6) * -1);
    }
  }
}

@media (--desktop) {
  .description {
    margin: var(--spacing-4) 0;
  }
  .image {
    img {
      width: 100%;
      margin: unset;
    }
  }
}
