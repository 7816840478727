.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  display: none;

  @media (--desktop) {
    display: block;
  }
}
.mobileImage {
  display: block;
  object-fit: contain;

  @media (--desktop) {
    display: none;
  }
}
