.card {
  padding: 0 var(--spacing-5);

  & .title {
    margin-top: 0;
    margin-bottom: var(--spacing-4);
    font-weight: 700;
  }

  & .text {
    margin-top: var(--spacing-4);
    margin-bottom: var(--spacing-6);
  }
  & .cta {
    width: 100%;
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  & .contentContainer {
    padding: var(--spacing-10) 0;
  }
  & .imageContainer {
    width: 100vw;
    margin: 0 calc(var(--spacing-4) * -1);
    padding: 0 !important;
    max-width: unset !important;
    flex: unset !important;
  }
}

@media (--tablet) {
  .card {
    & .imageContainer {
      margin: 0 calc(var(--spacing-5) * -1);
    }
  }
}

@media (--tablet-l) {
  .card {
    & .imageContainer {
      margin: 0 calc(var(--spacing-6) * -1);
    }
  }
}

@media (--desktop) {
  .card {
    padding: var(--spacing-16) var(--spacing-5);

    & .cta {
      width: 300px;
    }

    & .contentContainer {
      padding: unset;
    }

    & .imageContainer {
      flex: 0 0 calc(var(--grid-col-width) * 6) !important;
      max-width: calc(var(--grid-col-width) * 6) !important;
      width: 100%;
      margin: unset;
      padding-left: calc(var(--spacing-4) / 2) !important;
      padding-right: calc(var(--spacing-4) / 2) !important;
    }
  }
}

@media (--desktop-l) {
  .card {
    & .imageContainer {
      padding-left: calc(var(--spacing-6) / 2) !important;
      padding-right: calc(var(--spacing-6) / 2) !important;
    }
  }
}
