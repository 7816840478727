.frFinanceCopy {
  display: block;
  align-items: center;
  justify-content: center;
  padding: 12px;
  gap: var(--spacing-2);
  color: var(--color-tarmac-grey-700);
  background-color: var(--color-tarmac-grey-100);
  position: sticky;
  line-height: 18px;
  z-index: 52;

  @media (--tablet) {
    margin-left: 40px;
    line-height: none;
    width: 100%;
  }

  @media (--desktop) {
    font-size: 12px;
    padding: var(--spacing-3) 0 var(--spacing-3) var(--spacing-3);
    width: 90%;
  }
  @media (--desktop-l) {
    margin-left: 20px;
  }

  & > * {
    font-size: 12px !important;
  }
}

.campaignDisclaimer {
  display: block;
}
