.wrapper {
  max-width: 1312px;
  font-weight: 200;

  &[data-variant='without_padding'] {
    & .innerWrapper > li {
      align-items: flex-start;
      padding: 0;
      background-color: transparent;
      text-align: left;
    }
  }
}

.headline {
  margin-bottom: 24px;
  @media (--tablet-l) {
    margin-bottom: 40px;
  }
}

.headline {
  margin-bottom: 24px;
  @media (--tablet-l) {
    margin-bottom: 40px;
  }
}

.innerWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  @media (--tablet-s) {
    flex-direction: row;
    & > li {
      width: 32% !important;
      padding: var(--spacing-4);
      margin-bottom: 0px;
    }
  }

  & > li {
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--color-sunbeam-blue-50);
    width: 100%;
    padding: var(--spacing-6);
    margin-bottom: var(--spacing-3);
    text-align: center;

    & > .imageContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-sunbeam-blue-500);
      width: 64px;
      height: 64px;
      border-radius: 60px;
      margin-bottom: var(--spacing-5);
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;

      & > img {
        max-width: 40px;
        object-fit: contain;
        object-position: center;
      }
    }
    & > .content {
      display: flex;
      flex-direction: column;
      & > h3 {
        font-size: 20px;
        margin-bottom: var(--spacing-3);
      }
      & p {
        font-size: 16px;
        & > a {
          color: var(--neutral-700);
          &::after {
            background-color: var(--neutral-700);
          }
        }
      }
    }
  }

  @media (--tablet-l) {
    flex-direction: row;
    & > li {
      padding: var(--spacing-10);
      margin-bottom: 0px;
    }
  }
}

.cta {
  padding: var(--spacing-5);
  @media (--tablet) {
    max-width: 354px;
  }
  @media (--tablet-l) {
    display: none;
  }
}

& [data-tenant='rac'] {
  & .headline {
    margin-bottom: 24px;
    @media (--tablet-l) {
      margin-bottom: 40px;
    }
  }

  & .innerWrapper {
    & > li {
      background-color: var(--step-background-color, var(--color-sunbeam-blue-50));
      & > .imageContainer {
        background-color: var(--color-mica-blue-100);
      }
    }
  }
}
