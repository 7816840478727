.makeLogoWithTitle {
  display: grid;
  grid-template-columns: 1fr;
  padding-top: var(--spacing-10);
  align-items: center;
  align-self: flex-start;

  @media (--tablet) {
    grid-template-columns: [left] 0.2fr [right] 1fr;
    align-self: auto;
  }

  @media (--desktop) {
    grid-template-columns: [left] 0.1fr [right] 1fr;
  }

  & .image {
    position: relative;
    height: 80px;
    img {
      width: auto !important;
      @media (--tablet) {
        width: 100% !important;
      }
    }
  }
}

.reviewHeader {
  padding-top: var(--spacing-10);
  padding-bottom: 0;

  @media (--tablet) {
    padding: 0;
  }
}
