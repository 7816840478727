.linksBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  padding: 0 var(--spacing-5);

  & h1,
  h2,
  h3,
  h4,
  p {
    margin: 0;
    padding: 0;
    &:has(+ p, + ul, + ol) {
      margin: 0 0 var(--spacing-5);
    }
  }

  & .headingDescriptionContainer {
    padding-bottom: var(--spacing-6);

    & p.description {
      padding-top: var(--spacing-2);
      font-weight: 400;
    }
  }
  & .gridRow {
    row-gap: var(--spacing-3);
    width: 100%;
  }

  @media (--tablet) {
    padding: 0 var(--spacing-6);
  }

  @media (--desktop-l) {
    padding: 0 var(--spacing-16);

    & .headingDescriptionContainer {
      padding-bottom: var(--spacing-8);

      & .description {
        padding-top: var(--spacing-4);
      }
    }
  }
}
