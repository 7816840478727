.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #040f2199;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;

  pointer-events: none;
  contain: strict;

  &[data-is-visible='true'] {
    opacity: 1;
    visibility: visible;
    pointer-events: all;

    :is(body, html):has(&) {
      overflow: clip;
    }
  }

  &[data-is-visible='true'] .container {
    transform: translateY(0);
    opacity: 1;
  }
}

.container {
  background-color: var(--color-championship-white);
  width: 100%;
  max-width: 800px;
  transform: scale(0.95);
  opacity: 0;
  transition: 0.3s;
  /* overflow: auto; */
  max-height: 90vh;
  display: flex;
  flex-direction: column;

  @media (--mobile) {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    max-width: initial;
    transform: translateY(20px);
    max-height: 100vh;
  }
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 var(--spacing-10);
  border-bottom: 1px solid var(--color-tarmac-grey-50);
  height: 64px;
  font-size: 20px;
  flex: 0 0 64px;

  @media (--mobile) {
    padding: 0 var(--spacing-5);
  }

  & h5 {
    line-height: 1;
    padding: 0;
    margin: 0;
    font-size: 20px;
  }
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  margin: 0;
  padding: 0;
}

.content {
  padding: var(--spacing-8) var(--spacing-10) var(--spacing-12);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-4);
  flex: 1 1 auto;
  overflow: scroll;
  text-align: left;

  font-size: 16px;
  line-height: 26px;
  font-weight: var(--font-weight-regular);
  font-family: var(--font-family-system);

  @media (--mobile) {
    padding: var(--spacing-8) var(--spacing-5) var(--spacing-9);
  }

  & > * {
    margin: 0;
  }

  & p,
  & h4,
  & h5,
  & h3 {
    margin: 0;
  }
}

.icon {
  width: 75px;
  height: 75px;
  background-color: var(--color-mica-blue-100);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.infoIcon {
  cursor: pointer;
  padding: 0 var(--spacing-2);
}
