.reviewMobBannerAd {
  &&& {
    margin-bottom: var(--spacing-2);
  }
}

.reviewMainBillboardAd {
  width: 100%;
  order: 1;

  @media (min-width: 550px) {
    order: 2;
  }

  @media (--mobile) {
    &:has(.reviewMobileAds:empty) {
      display: none;
    }
  }

  @media (--tablet) {
    &:has(.reviewDesktopAds:empty) {
      display: none;
    }
  }

  @media (--tablet-l) {
    order: 3;
  }
}

.reviewDesktopAds {
  display: none;

  &:empty {
    display: none;
  }

  @media (--tablet-l) {
    display: block;
  }
}

.reviewMobileAds {
  display: block;

  &:empty {
    display: none;
  }

  @media (--tablet) {
    display: none;
  }
}

.reviewMobileTabletAds {
  display: block;

  &:empty {
    display: none;
  }

  @media (--tablet-l) {
    display: none;
  }
}
