.wrpr {
  display: grid;
  grid-auto-flow: row;
  width: 100%;

  & .ttl,
  & [data-accordion-component='AccordionItemPanel'] {
    width: 100%;
  }

  & .ttl h3 {
    margin-top: 0;
  }

  & .layout {
    display: grid;
    grid-auto-flow: row;
    gap: var(--spacing-6);
    white-space: normal;
    text-align: left;

    & a {
      white-space: normal;
      text-align: left;
    }
  }

  & .list {
    display: block;
    columns: 1;

    & a {
      text-decoration: none;
    }

    & button {
      min-height: auto;
    }
  }

  & .links {
    display: block;
    text-align: left;
    text-decoration: none;
    white-space: normal;
    margin-bottom: var(--spacing-6);
    color: var(--button-primary-base-bg-color);
    font-size: clamp(14px, 1.1vw, 16px);
    line-height: 1.3;
    font-weight: var(--font-weight-regular);

    & button {
      padding: 0;
    }
  }

  @media (--tablet) {
    & .layout {
      grid-auto-flow: column;
      grid-auto-columns: 1fr;
    }

    & .list {
      columns: 162px 4;
      column-gap: var(--spacing-6);
      &[data-three-columns='true'] {
        columns: 162px 3;
      }
    }
  }

  @media (--tablet-l) {
    grid-auto-flow: column;
    grid-template-columns: min-content max-content;

    & .ttl {
      width: 302px;
      margin-right: var(--spacing-16);
    }

    & .layout {
      & > div:nth-child(3n + 1) {
        grid-column: 1;
      }
      & > div:nth-child(3n + 2) {
        grid-column: 2;
      }
      & > div:nth-child(3n + 3) {
        grid-column: 3;
      }
    }
  }

  @media (--desktop) {
    & .ttl {
      width: 365px;
      margin-right: var(--spacing-20);
    }

    & .layout > div:nth-child(3n + 4) {
      grid-column: 4;
    }
  }
}
