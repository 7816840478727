.wrapper {
  padding-top: var(--spacing-4);
  padding-bottom: var(--spacing-6);

  @media (--tablet) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: var(--spacing-10);
    padding-bottom: var(--spacing-4);
  }

  @media (--desktop) {
    padding-top: var(--spacing-8);
    padding-bottom: var(--spacing-8);
  }

  &&& {
    margin-bottom: 0;
  }

  a {
    text-decoration: none;
  }
}

.title {
  margin: 0;

  @media (--desktop) {
    font-size: 32px;
    line-height: 40px;
  }
}
