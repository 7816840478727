.wrapper {
  display: flex;
  align-items: center;
  gap: var(--spacing-2);

  margin-top: var(--spacing-3);
  padding-top: var(--spacing-3);
  border-top: solid 1px var(--color-neutral-100);

  & p {
    margin: 0;
    color: var(--color-primary-500);
  }
  & span {
    font-weight: bold;
  }

  @media (--tablet) {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}

.imgContainer {
  overflow: hidden;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: solid 2px var(--color-primary-500);
}
