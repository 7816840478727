.wrapper {
  padding-top: var(--spacing-3);
  && > * {
    margin-bottom: var(--spacing-14);

    &:last-child,
    &.breadcrumb {
      margin-bottom: 0;
    }
  }

  @media (--tablet) {
    padding-top: var(--spacing-10);
  }

  & .headerWrapper {
    background-color: var(--color-mica-blue-50);
    margin-top: -12px;
    padding: var(--spacing-10) var(--spacing-5);
    @media (--tablet) {
      margin-top: -40px;
    }
  }

  & .fullWwrapper {
    margin-bottom: var(--spacing-10);
  }

  & .addDivider {
    border-top: 1px solid var(--color-tarmac-grey-300);
    margin: 0;
  }
}

.searchWrapper {
  margin-bottom: var(--spacing-10) !important;
  @media (--desktop) {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    && > * {
      margin: 0 !important;
    }
  }
}

.twoColLayout {
  display: flex;
  gap: 48px;
  border-top: 1px solid var(--color-tarmac-grey-300);
  padding-top: var(--spacing-10);

  & .contentCol {
    max-width: 100%;
    width: 100%;
    @media (--desktop-l) {
      max-width: calc(100% - 348px);
      width: auto;
    }
    & :global(.reviews-scrollbox) {
      @media (--desktop-l) {
        width: 100%;
        overflow-y: hidden;
      }
    }
  }

  & .adCol {
    display: none;

    @media (--desktop-l) {
      display: block;
      width: 300px;
    }
  }
}
