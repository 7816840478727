.row:not(:has(.author)) {
  padding-bottom: var(--spacing-10);
}

.author {
  margin: var(--spacing-6) 0;
}

.date {
  margin: 0;
}

.title > h1 {
  margin: 0;
}

.date + .title > h1 {
  margin-top: var(--spacing-4);
}
.image {
  margin: 0 calc(var(--spacing-5) * -1);
}

.authorLink {
  text-decoration: none;
}

@media (--tablet) {
  .row:not(:has(&-author)) {
    padding-bottom: var(--spacing-12);
  }
  .author {
    margin: var(--spacing-6) 0 var(--spacing-12);
  }
  .image {
    margin: 0 calc(var(--spacing-6) * -1);
  }
}

@media (--tablet-large) {
  .mage {
    margin: 0 calc(var(--spacing-8) * -1);
  }
}

@media (--desktop) {
  .row:not(:has(.author)) {
    padding-bottom: var(--spacing-16);
  }

  .author {
    margin: var(--spacing-6) 0 calc(var(--spacing-9) * 2);
  }

  .image {
    margin: 0 calc(var(--spacing-16) * -1);
    & img {
      object-fit: cover;
    }
  }
}
