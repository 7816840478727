.interlinking {
  display: grid;
  grid-auto-flow: row;
  width: 100%;

  @media (--tablet-l) {
    grid-auto-flow: column;
    grid-template-columns: min-content max-content;
  }

  && {
    .title {
      width: 100%;

      @media (--tablet-l) {
        width: 302px;
        margin-right: var(--spacing-16);

        & h3 {
          margin-top: 0;
        }
      }

      @media (--desktop) {
        width: 365px;
        margin-right: var(--spacing-20);
      }
    }

    .layout {
      text-align: left;
      overflow-wrap: normal;
      max-width: 100%;
      margin: 0;

      @media (--tablet-l) {
        max-width: 656px;
      }
    }
  }
}
