.richTextComponent {
  width: 100%;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:has(+ p, + ul, + ol) {
      margin: 0 0 0.5em;
    }
  }
}
