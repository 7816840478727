.wrapper {
  margin: 0px var(--spacing-5);

  @media (--desktop-m) {
    margin: var(--spacing-10) var(--spacing-16);
  }

  & > h2 {
    padding: var(--spacing-5) 0px;
    font-size: 28px;

    @media (--desktop-m) {
      padding: var(--spacing-10) 0px;
    }
  }

  & > .stepsWrapper {
    & > li {
      list-style: none;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap-reverse;
      padding: var(--spacing-5) 0;

      @media (--tablet-m) {
        padding: var(--spacing-10) 0;
      }

      @media (--desktop-m) {
        padding: var(--spacing-10) 0;
      }

      & > .left {
        display: flex;
        align-items: flex-start;
        width: 100%;

        @media (--tablet-m) {
          width: 63%;
        }

        @media (--desktop-m) {
          width: 60%;
        }

        & > .iconContainer {
          display: none;
          align-items: center;
          justify-content: center;
          background-color: #f3f3f3;
          border-radius: 60px;
          width: 80px;
          aspect-ratio: 1;

          & > img {
            width: 40px;
            aspect-ratio: 1;
          }

          @media (--desktop-m) {
            display: flex;
          }
        }

        & > .content {
          margin-top: calc(-1 * var(--spacing-4));
          width: fit-content;

          @media (--desktop-m) {
            margin-left: var(--spacing-8);
          }

          & > b {
            font-size: 48px;
            display: block;
            margin-top: calc(-1 * var(--spacing-5));

            @media (--tablet-m) {
              margin-top: 0px;
            }

            @media (--desktop-m) {
              font-size: 60px;
            }
          }

          & > h3 {
            font-size: 20px;
            font-weight: bold;
          }

          & div[class*='content-block'] {
            & > p {
              margin-top: var(--spacing-5);

              @media (--desktop-m) {
                margin-top: var(--spacing-10);
              }

              & > a {
                width: fit-content;
                display: flex;
                margin-top: var(--spacing-3);
                color: var(--neutral-700);
                &::after {
                  background-color: var(--neutral-700);
                }
              }

              & > strong {
                width: fit-content;
                display: flex;
                margin-top: var(--spacing-5);
              }
            }
          }
        }
      }

      & > .right {
        @media (--tablet-m) {
          width: 35%;
        }

        @media (--desktop-m) {
          width: 32%;
        }

        & > img {
          object-fit: cover;
          width: 100%;
          height: 175px;
          @media (--tablet-m) {
            height: 230px;
          }
        }
      }
    }
  }

  & .cta {
    width: 100%;
    margin-left: 0px;

    @media (--tablet-m) {
      max-width: 350px;
      margin-left: var(--spacing-4);
    }

    @media (--desktop-m) {
      max-width: 256px;
      margin-left: calc(var(--spacing-16) + var(--spacing-10));
    }
  }
}
