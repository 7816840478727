.prosAndCons {
  margin-bottom: var(--spacing-14);

  & .cons {
    margin-top: var(--spacing-8);
  }

  & h2 {
    margin-top: 0;
    margin-bottom: var(--spacing-4);
  }

  & ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  & .list {
    & li {
      display: flex;
      width: 100%;
      margin-bottom: 1rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      & svg {
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 1rem;
      }

      & p {
        margin: 0;
      }
    }
  }
}

@media (min-width: 768px) {
  .prosAndCons {
    & .cons {
      margin-top: 0;
    }
  }
}
