.interlinking {
  display: grid;
  grid-auto-flow: row;
  width: 100%;

  @media (--tablet-l) {
    grid-auto-flow: column;
    grid-template-columns: max-content;
  }

  && {
    & h6 {
      margin: 0;
    }

    &[data-accordion-component] {
      width: 100%;
    }

    & [data-accordion-component='AccordionItemPanel'] {
      width: 100%;
    }

    & h6 {
      font-size: clamp(14px, 1.1vw, 16px);
      line-height: 1.3;
    }

    .title {
      width: 100%;

      @media (--tablet-l) {
        width: 302px;
        margin-right: var(--spacing-16);
      }

      @media (--desktop) {
        width: 365px;
        margin-right: var(--spacing-20);
      }
    }

    .layout {
      display: grid;
      grid-auto-flow: row;
      gap: var(--spacing-6);

      white-space: normal;
      text-align: left;

      @media (--tablet) {
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
      }

      @media (--tablet-l) {
        & > div:nth-child(3n + 1) {
          grid-column: 1;
        }
        & > div:nth-child(3n + 2) {
          grid-column: 2;
        }
        & > div:nth-child(3n + 3) {
          grid-column: 3;
        }
      }

      @media (--desktop) {
        & > div:nth-child(3n + 4) {
          grid-column: 4;
        }
      }

      & a {
        white-space: normal;
        text-align: left;
      }
    }

    .locationLayout {
      grid-auto-flow: row;
      gap: var(--spacing-6);
      white-space: normal;
      text-align: left;
      && {
        margin: 0;
        padding: 0;
      }

      @media (--tablet) {
        grid-template-columns: repeat(4, 1fr);
      }
    }

    .hideSectionForTblt {
      display: none;
      @media (--tablet) {
        display: grid;
        padding: 0;
      }
    }

    .mobileView {
      display: grid;
      padding: 0;
      @media (--tablet) {
        display: none;
      }

      & .viewMore {
        width: fit-content;
      }
    }

    .blockHeader {
      font-weight: bold;
      margin: var(--spacing-2) 0;

      @media (--tablet) {
        min-height: var(--spacing-9);
      }

      @media (--desktop) {
        min-height: var(--spacing-10);
      }
    }

    .list {
      display: grid;
      gap: var(--spacing-6);

      @media (--tablet) {
        grid-auto-rows: max-content;
      }

      & h6 {
        margin: var(--spacing-2) 0;

        @media (--tablet) {
          min-height: var(--spacing-9);
        }

        @media (--desktop) {
          min-height: var(--spacing-10);
        }
      }

      & a {
        text-decoration: none;
      }

      & button {
        min-height: auto;
      }
    }

    .links {
      text-align: left;
      text-decoration: none;
      white-space: normal;

      & button {
        padding: 0;
      }

      & span {
        font-size: clamp(14px, 1.1vw, 16px);
        font-weight: var(--font-weight-regular);
        line-height: 1.3;
      }
    }
  }
}

& [data-tenant='rac'] {
  .layout {
    & button {
      color: var(--color-rac-grey-100);
      transition: 0.1s;

      &:hover {
        color: var(--color-primary-500);
      }
    }
  }
}
